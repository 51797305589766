/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
// $warning: #f8991d;
$info: #3FA592;

.theme-navbar {
    background-color: #3D97C4;
    color: #fff;

    .nav-link {
        color: #fff;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #3FA592;
        border-radius: 2px;
    }
}

.theme-footer {
    background-color: #EFC100;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #696767
    }
}

.brand-logo {
    height: auto;
    width: 150px;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
    background-color: #d61212 !important;
  }
  .swiper-button-prev {
    color: #004b91 !important;
    background-color: rgba(253, 253, 253, 0.6);
  }
  .swiper-button-next {
    color: #004b91 !important;
    background-color: rgba(253, 253, 253, 0.6);
  }
  .swiper-wrapper{
      height: 94% !important; 
  }

@media (max-width: 576px) {
  .mobile_center {
    text-align: center;
    button {
      width: 100%;
    }
  }
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"