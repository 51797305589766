.cover_thumb {
    max-width: 150px;
    max-height: 200px;
}

.cover_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}